body
{
  font-family: 'Linotype Univers W01',Arial,Helvetica,sans-serif;
}
.App {
  text-align: center;
}

.checkpic
{
  display: none;
}