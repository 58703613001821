.namediv
{
  @apply text-4xl;
  @apply bg-refra text-white overflow-hidden p-2;
}
.textdiv
{
    @apply text-2xl;
    @apply p-5;
}
.box
{
  @apply border-black border-4 border-solid rounded-xl overflow-hidden;
}

.iconimg
{
    @apply h-10 inline-block mr-2
}
.nametext
{
    display: inline-block;
    vertical-align: middle;
}
.box:hover
{
      box-shadow: 3px 5px 4px 5px #888888;
}